export interface Config {
  serverUrl: string
  username: string;
  password: string;
  guestUsername: string;
  guestPassword: string;
  serverOptions: { value: string; label: string }[];
}

const env = (window as any).env

export const config: Config = {
  serverUrl: env?.SERVER_URL || '',
  username: env?.USERNAME || '',
  password: env?.PASSWORD || '',
  guestUsername: env?.GU || 'guest',
  guestPassword: env?.GP || 'z9k;l8KqlP',
  serverOptions: [
    { value: 'https://airdirect.thdisc.tk', label: 'North America' },
    { value: 'https://airdirect2.thdisc.tk', label: 'East Asia' },
    { value: 'https://cdn.thdisc.pp.ua', label: 'Europe' },
  ],
}
