<template>
  <div class="d-flex align-items-center mb-2">
    <button class="navbar-toggler text-white d-md-none" @click="store.showMenu">
      <Icon icon="nav" />
    </button>
  <div class="ml-auto" />

  <SearchForm />
<!--  <button @click="handleThemeToggle" class="theme-toggle"> -->
<!--     <img v-if="theme === 'dark'" src="@/shared/assets/moon.svg" alt="moon" /> -->
<!--     <img v-else src="@/shared/assets/sun.svg" alt="sun" /> -->
<!--  </button> -->

<button @click="handleThemeToggle" class="theme-toggle">
  <Icon :icon="theme === 'dark' ? 'moon' : 'sun'" />
</button>

    <template v-if="store.username && showDropdown">
      <b-dropdown variant="link" right no-caret>
        <template #button-content>
          <Avatar>
            <Icon icon="person" class="user-icon" />
          </Avatar>
        </template>

        <b-dropdown-text>
          {{ store.username }}
        </b-dropdown-text>

        <b-dropdown-divider />
        <b-dropdown-text>
          <label>Access Line</label>
          <select v-model="selectedServer" class="form-control" @change="switchServer" style="width: 150px;">
            <option v-for="option in config.serverOptions" :key="option.value" :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </b-dropdown-text>

        <b-dropdown-item v-if="isAdmin" :href="store.server" target="_blank" rel="noopener noreferrer">
          Server <Icon icon="link" />
        </b-dropdown-item>
        <b-dropdown-item-button v-if="isAdmin" @click="scan">
          Scan media folders
        </b-dropdown-item-button>
        <b-dropdown-item-button v-if="isAdmin" @click="showAboutModal = true">
          About
        </b-dropdown-item-button>

        <b-dropdown-divider />
        <b-dropdown-item-button @click="logout">
          Log out
        </b-dropdown-item-button>
      </b-dropdown>
    </template>
    <About :visible="showAboutModal" @close="showAboutModal = false" />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import About from './About.vue'
  import SearchForm from '@/library/search/SearchForm.vue'
  import { useMainStore } from '@/shared/store'
  import { useAuth } from '@/auth/service'
  import { config } from '@/shared/config' // 確保正確引入 config
  import { isGuestUser, isAdmin } from '@/shared/utils'

  export default defineComponent({
    computed: {
      isGuestUser() {
        return isGuestUser()
      },
      isAdmin() {
        return isAdmin()
      }
    },
    components: {
      About,
      SearchForm,
    },
    setup() {
      return {
        store: useMainStore(),
        auth: useAuth(),
        config,
      }
    },
    data() {
      return {
        theme: localStorage.getItem('theme') || 'dark', // 預設為暗色模式
        showDropdown: true,
        showAboutModal: false,
        selectedServer: '',
        sidebarColor: 'dark', // 預設側邊欄顏色
      }
    },
    created() {
      // 預設選中當前服務器
      this.selectedServer = this.auth.server || config.serverOptions[0].value
    },
    mounted() {
      // 初始化時根據儲存的主題應用樣式
      document.body.classList.add(`${this.theme}-mode`)
    },
    methods: {
      handleThemeToggle() {
        this.theme = this.theme === 'dark' ? 'light' : 'dark'
        this.$emit('toggle-theme')
      },
      scan() {
        return this.$api.scan()
      },
      logout() {
        this.auth.logout()
        this.$router.go(0)
      },
      switchServer() {
        this.auth.setServer(this.selectedServer)
        localStorage.setItem('lastServer', this.selectedServer)
        this.$router.go(0) // 刷新頁面應用新伺服器
      }
    }
  })
</script>

<style>
.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 0px; /* 與使用者名稱間距 */
  margin-right: 0px;
  margin-left: 10px;
}
/* .theme-toggle img { */
/*   width: 32px; */
/*   height: 32px; */
/* } */
.theme-toggle .icon {
  font-size: 1.7rem; /* 根据需要调整图标大小 */
  color: currentColor; /* 使用当前文本颜色 */
  transition: color 0.3s; /* 添加平滑过渡效果 */
}

/* 白色主題的下拉選單樣式 */
.dropdown-menu {
  background-color: #f1f1f1 !important; /* 強制白色背景 */
  color: #222222 !important; /* 深色文字 */
  border: 2px solid #ddd; /* 邊框顏色 */
  padding: 10px;
}

/* 深色主題的下拉選單樣式 */
body.dark-mode .dropdown-menu {
  background-color: #161616 !important; /* 強制黑色背景 */
  color: #ffffff !important; /* 白色文字 */
  border: 2px solid #555; /* 邊框顏色 */
  padding: 10px;
}

/* 以下是log out默认主题样式 */
button.dropdown-item {
  color: #222222; /* 默认文字颜色 */
  background-color: #f1f1f1; /* 默认背景颜色 */
}
button.dropdown-item:hover {
  color: #ffffff; /* 默认悬停文字颜色 */
  background-color: #b3b3b3; /* 默认悬停背景颜色 */
}

/* .dark-mode log out 主题样式 */
.dark-mode button.dropdown-item {
  color: #ffffff; /* 暗模式文字颜色 */
  background-color: #161616; /* 暗模式背景颜色 */
}
.dark-mode button.dropdown-item:hover {
  color: #e0e0e0; /* 暗模式悬停文字颜色 */
  background-color: #252525; /* 暗模式悬停背景颜色 */
}

</style>

<style scoped>
.icon {
  color: rgb(97, 97, 97) /* 白色主題默認顏色 */
}
/* 深色主題 */
body.dark-mode .icon {
  color: white; /* 黑色主題顏色 */
}

.user-icon {
  color: #e7e7e7; /* 白色主題 */
}
body.dark-mode .user-icon {
  color: #e2e2e2; /* 黑色主題 */
}

</style>
