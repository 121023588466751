<template>
  <div>
    <div class="min-vh-100 d-flex">
      <Sidebar :sidebarColor="sidebarColor" />
      <main class="container-fluid py-2">
        <TopNav @toggle-theme="toggleTheme" />
        <slot />
      </main>
    </div>
    <Player />
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import TopNav from '@/app/TopNav.vue'
  import Sidebar from '@/app/Sidebar.vue'
  import Player from '@/player/Player.vue'

  export default defineComponent({
    components: {
      TopNav,
      Sidebar,
      Player,
    },
    data() {
      return {
        theme: localStorage.getItem('theme') || 'dark', // 預設主題
        sidebarColor: 'dark', // 預設側邊欄顏色
      }
    },
    watch: {
      theme(newTheme) {
        this.sidebarColor = newTheme
      }
    },
    mounted() {
      this.sidebarColor = this.theme
    },
    methods: {
      toggleTheme() {
        this.theme = this.theme === 'dark' ? 'light' : 'dark'
        document.body.className = ''
        document.body.classList.add(`${this.theme}-mode`)
        localStorage.setItem('theme', this.theme)
        this.$forceUpdate()
      },
    },
  })
</script>

<style lang="scss">
  main {
    margin-bottom: 80px;
    overflow-x: hidden;
  }
</style>
