import { render, staticRenderFns } from "./Tile.vue?vue&type=template&id=324c11ff&functional=true"
var script = {}
import style0 from "./Tile.vue?vue&type=style&index=0&id=324c11ff&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports