<template>
  <div class="row align-items-center h-100 mt-5">
    <div v-if="!displayForm" class="mx-auto">
      <span class="spinner-border " />
    </div>
    <div v-else class="mx-auto card" style="width: 22rem">
      <b-overlay rounded :show="busy" opacity="0.1">
        <div class="card-body">

          <form @submit.prevent="login">
            <div class="d-flex mb-2">
              <Logo class="mx-auto" />
            </div>

            <div class="form-group">
              <label>Access Line</label>
              <select v-model="server" name="server" class="form-control">
              <option value="" disabled>Please select a server</option>
              <option v-for="option in config.serverOptions" :key="option.value" :value="option.value">
              {{ option.label }}
              </option>
              </select>
            </div>

            <div v-if="!config.username" class="form-group">
              <label>Username</label>
              <input v-model="username" name="username" type="text"
                     class="form-control" :class="{'is-invalid': hasError}" :style="{ color: isTransparent ? 'transparent' : 'inherit' }" :readonly="isTransparent">
            </div>
            <div v-if="!config.password" class="form-group">
              <label>Password</label>
              <input v-model="password" name="password" type="password"
                     class="form-control" :class="{'is-invalid': hasError}" :style="{ color: isTransparent ? 'transparent' : 'inherit' }" :readonly="isTransparent">
            </div>
            <div v-if="error != null" class="alert alert-danger">
              Could not log in. ({{ error.message }})
            </div>
            <button class="btn btn-primary btn-block" :disabled="busy" :style="{ color: theme === 'dark' ? '#ffffff' : '#000000' }" @click="login">
              <span v-show="false" class="spinner-border spinner-border-sm" /> Log in
            </button>
            <!-- 新增訪客登入按鈕 -->
            <button
              class="btn btn-secondary btn-block mt-2 btn-g"
              :style="{ color: theme === 'dark' ? '#ffffff' : '#000000' }"
              @click="guestLogin">
              {{ guestLoginButtonText }}
            </button>
          </form>
        <div>
          &nbsp;
        </div>
          <div class="BT">
            <!-- 修復後的主題切換按鈕 -->
            <button type="button" @click="toggleTheme" class="theme-toggle">
              <Icon :icon="theme === 'dark' ? 'moon' : 'sun'" />
            </button>
          </div>

        </div>
      </b-overlay>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { config } from '@/shared/config'
  import Logo from '@/app/Logo.vue'
  import { useMainStore } from '@/shared/store'
  import { useAuth } from '@/auth/service'
  import { BOverlay } from 'bootstrap-vue'

  export default defineComponent({
    components: {
      BOverlay,
      Logo,
    },
    props: {
      returnTo: { type: String, required: true },
    },
    setup() {
      const store = useMainStore()
      return {
        store: useMainStore(),
        auth: useAuth(),
        theme: localStorage.getItem('theme') || 'dark', // 預設為暗色模式
      }
    },
    data() {
      return {
        server: '',
        username: '',
        password: '',
        busy: false,
        error: null,
        displayForm: false,
        isTransparent: false, // 新增屬性，控制帳號和密碼的顯示
      }
    },
    computed: {
      guestLoginButtonText() { // 黑白切換文字內容，目前切換不起效
        return this.theme === 'dark' ? 'Guest Log in' : 'Guest Log in'
      },
      hasError(): boolean {
        return this.error !== null
      },
      config: () => config
    },
    async created() {
      this.server = this.auth.lastServer || this.auth.server || config.serverUrl || config.serverOptions[0].value
      this.username = this.auth.username || config.username
      this.password = config.password

      const success = await this.auth.autoLogin()
      if (success) {
        this.store.setLoginSuccess(this.username, this.server)
        await this.$router.replace(this.returnTo)
      } else {
        this.displayForm = true
      }
    },
    methods: {
      toggleTheme() {
        this.theme = this.theme === 'dark' ? 'light' : 'dark'
        localStorage.setItem('theme', this.theme)
        document.body.className = ''
        document.body.classList.add(`${this.theme}-mode`)
        this.$forceUpdate()
      },
      login() {
        this.error = null
        this.busy = true
        this.auth.loginWithPassword(this.server, this.username, this.password)
          .then(() => {
            this.store.setLoginSuccess(this.username, this.server)
            localStorage.setItem('lastServer', this.server)
            if (this.$router.currentRoute.path !== this.returnTo) {
              this.$router.replace(this.returnTo)
            }
          })
          .catch(err => {
            this.error = err
          })
          .finally(() => {
            this.busy = false
          })
      },
      guestLogin() {
        // 設定訪客登入的邏輯
        this.username = config.guestUsername
        this.password = config.guestPassword
        this.isTransparent = true // 透明隱藏
        this.login() // 調用 login 方法進行登入
      }
    }
  })
</script>

<style scoped>
.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
}
.BT {
  display: flex;
  /* 水平置中 */
  justify-content: center;
  /* 垂直置中 */
  align-items: center;
}
.btn-g:active,
.btn-g:focus{
  background-color: #747474 !important; /* 點選时背景色 */
  color: #ffffff !important;
}
.btn-g:hover {
  background-color: #a5a5a5 !important; /* 點選时背景色 */
}
.btn-g {
  background-color: #dfdfdf; /* 白色時的顏色 */
}
.dark-mode .btn-g {
  background-color: #424242; /* 黑色時的顏色 */
}
.dark-mode .btn-g:hover {
  background-color: #636363 !important; /* 黑色時的懸停顏色 */
}

.icon {
  color: rgb(97, 97, 97) /* 白色主題默認顏色 */
}
/* 深色主題 */
body.dark-mode .icon {
  color: white; /* 黑色主題顏色 */
}
</style>
