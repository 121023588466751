<template>
  <div>
    <form class="form-inline my-2 my-lg-0" @submit.prevent="search">
      <input
        v-model="query"
        class="form-control mr-sm-2" type="search"
        placeholder="Search">
    </form>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    data() {
      return {
        query: ''
      }
    },
    methods: {
      search(): void {
        this.$router.push({ name: 'search', query: { q: this.query } })
      }
    }
  })
</script>

<style>
/* 搜尋框 - 淺色主題 */
body.light-mode .form-control {
  background-color: #f7f7f7; /* 淺色主題背景色 */
  color: black; /* 淺色主題文字顏色 */
}

/* 搜尋框 - 深色主題 */
body.dark-mode .form-control {
  background-color: #272727; /* 深色主題背景色 */
  color: white; /* 深色主題文字顏色 */
}

</style>
