<template>
  <nav :class="['nav flex-column', sidebarColor === 'dark' ? 'sidebar-dark' : 'sidebar-light']">
    <div class="sidebar-brand d-flex justify-content-between align-items-end">
      <Logo />
      <button class="btn btn-link btn-lg p-0 m-0 d-md-none" @click="store.hideMenu">
        <Icon icon="x" />
      </button>
    </div>

    <router-link class="nav-link" :to="{name: 'home'}" exact>
      <Icon icon="discover" class="" /> Discover
    </router-link>

    <router-link class="nav-link" :to="{name: 'queue'}">
      <Icon icon="playlist" /> Playing
    </router-link>

    <small class="sidebar-heading text-muted">
      Library
    </small>

    <router-link class="nav-link" :to="{name: 'albums-default'}">
      <Icon icon="library" /> Albums
    </router-link>

    <router-link class="nav-link" :to="{name: 'artists'}">
      <Icon icon="library" /> Artists
    </router-link>

    <router-link v-if="!isGuestUser" class="nav-item nav-link" :to="{name: 'genres'}">
      <Icon icon="library" /> Genres
    </router-link>

    <router-link v-if="!isGuestUser" class="nav-link" :to="{name: 'playlists'}">
      <Icon icon="list" /> Playlists
    </router-link>

    <router-link v-if="!isGuestUser" class="nav-link" :to="{name: 'favourites'}">
      <Icon icon="heart" /> Favourites
    </router-link>
<!--
    <router-link v-if="!isGuestUser" class="nav-link" :to="{name: 'podcasts'}">
      <Icon icon="podcast" /> Podcasts
    </router-link>

    <router-link v-if="!isGuestUser" class="nav-link" :to="{name: 'radio'}">
      <Icon icon="radio" /> Radio
    </router-link>
-->
    <router-link class="nav-link" :to="{name: 'files'}">
      <Icon icon="files" /> Files
    </router-link>

    <PlaylistNav />
  </nav>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import Logo from './Logo.vue'
  import PlaylistNav from '@/library/playlist/PlaylistNav.vue'
  import { useMainStore } from '@/shared/store'
  import { isGuestUser, isAdmin } from '@/shared/utils'

  export default defineComponent({
    props: {
      sidebarColor: {
        type: String,
        required: true,
      },
    },
    components: {
      Logo,
      PlaylistNav,
    },
    computed: {
      isGuestUser() {
        return isGuestUser()
      },
      isAdmin() {
        return isAdmin()
      }
    },
    setup() {
      return {
        store: useMainStore(),
      }
    },
  })
</script>

<style lang="scss">
@import '@/style/_variables.scss';

/* .nav {
  height: 100vh;
  width: 250px;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: #fff;
  padding: 0 8px;
} */

</style>
