<template>
<div>
  <b-modal content-class="custom-modal-class" :visible="visible" @ok="confirm" @change="change">
    <template #modal-header-close>
      <Icon icon="x" />
    </template>
    <template #modal-title>
      New playlist
    </template>
    <div class="form-group">
      <label>Name</label>
      <input v-model="name" class="form-control" type="text">
    </div>
    <template #modal-ok>
      Create
    </template>
  </b-modal>
</div>
</template>
<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { usePlaylistStore } from '@/library/playlist/store'

  export default defineComponent({
    props: {
      visible: { type: Boolean, required: true },
    },
    setup(props, { emit }) {
      const name = ref('')
      const store = usePlaylistStore()
      return {
        name,
        confirm: () => {
          store.create(name.value)
        },
        change: () => {
          name.value = ''
          emit('update:visible', false)
        },
      }
    },
  })
</script>

<style>
/* 全局样式覆盖模态窗口内容 */
/* 浅色模式 */
body.light-mode .custom-modal-class {
  background-color: #f1f1f1;
  color: #333333;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}
/* 深色模式 */
body.dark-mode .custom-modal-class {
  background-color: #141414 !important;
  color: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

body.light-mode .modal-title {
  color: #333333;
}

/* 输入框样式 */
body.light-mode .custom-modal-class .form-control {
  background-color: #f0f0f0;
  color: black;
  border: 1px solid var(--divider-color, #777777);
}
body.dark-mode .custom-modal-class .form-control {
  background-color: #dadada;
  color: black;
  border: 1px solid var(--divider-color, #e0e0e0);
}

/* 按钮样式 */
body.light-mode .custom-modal-class .btn {
  background-color: #e6e6e6;
  color: black;
  border: 1px solid var(--divider-color, #000000);
}
body.dark-mode .custom-modal-class .btn {
  background-color: #414141;
  color: #f5f5f5 !important;
}
</style>

<style scoped>
</style>
