import { render, staticRenderFns } from "./EmptyIndicator.vue?vue&type=template&id=380a0386&scoped=true&functional=true"
var script = {}
import style0 from "./EmptyIndicator.vue?vue&type=style&index=0&id=380a0386&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "380a0386",
  null
  
)

export default component.exports